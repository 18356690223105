import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import useAuthState from '../../stores/auth'

const ProviderNotes = () => {
  const { user } = useAuthState()
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [doctors, setDoctors] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!user || !user.id) {
      setError('User ID is missing or not authenticated')
      setLoading(false)
      return
    }

    const fetchData = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 500))

        const response = await fetch(
          `${process.env.GATSBY_API_URL}/medication_encounters/user/${user.id}`,
        )
        if (!response.ok) {
          const errorData = await response.json()
          throw new Error(errorData.message || 'Unknown error occurred')
        }
        const result = await response.json()
        setData(result[0])

        const doctorIds = result[0].referral
          .map(ref => ref.doctor)
          .filter(Boolean)
        const doctorPromises = doctorIds.map(id =>
          fetch(`${process.env.GATSBY_API_URL}/doctors/${id}`).then(res =>
            res.json(),
          ),
        )
        const doctorResponses = await Promise.all(doctorPromises)
        const doctorMap = doctorResponses.reduce((acc, doctor) => {
          acc[doctor._id] = doctor
          return acc
        }, {})
        setDoctors(doctorMap)
      } catch (error) {
        console.error('Error fetching medication encounters:', error)
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [user])

  if (error) return <ErrorMessage>{error}</ErrorMessage>
  if (loading)
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    )

  return (
    <Container>
      <Title>Your Provider Notes</Title>
      <ChatContainer>
        <ChatBubbleLeft>
          <Text>
            <strong>Follow-Up:</strong>{' '}
            {data.followUp || 'No follow-up information available'}
          </Text>
          <Timestamp>{new Date(data.date).toLocaleString()}</Timestamp>
        </ChatBubbleLeft>

        <ChatBubbleLeft>
          <Text>
            <strong>Recommendation to Patient:</strong>{' '}
            {data.recommendationToPatient || 'No recommendations available'}
          </Text>
          <Timestamp>{new Date(data.date).toLocaleString()}</Timestamp>
        </ChatBubbleLeft>

        <ChatBubbleLeft>
          <Text>
            <strong>Referral:</strong>
            {data.referral.length > 0
              ? data.referral.map(referral => {
                  const doctor = doctors[referral.doctor]
                  return (
                    <ReferralItem key={referral._id}>
                      <p>
                        <strong>Doctor:</strong>{' '}
                        {doctor
                          ? `${doctor.firstName} (${doctor.specialty})`
                          : 'Doctor details not available'}
                      </p>
                      <p>
                        <strong>Comment:</strong>{' '}
                        {referral.comment || 'No comment provided'}
                      </p>
                    </ReferralItem>
                  )
                })
              : 'No referral information available'}
          </Text>
          <Timestamp>{new Date(data.date).toLocaleString()}</Timestamp>
        </ChatBubbleLeft>
      </ChatContainer>
    </Container>
  )
}

export default ProviderNotes

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 80vh;

  @media (max-width: 600px) {
    width: 100%;
    height: 50vh;
    padding: 10px;
  }
`

const Container = styled.div`
  padding: 20px;
  width: 600px;
  height: 80vh;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  @media (max-width: 600px) {
    width: 100%;
    height: 50vh;
    padding: 10px;
  }
`

const Title = styled.div`
  font-size: 1.2em;
  border-radius: 8px 8px 0 0;
  background-color: #edf9ee;
  padding: 7px 9px;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 1em;
    padding: 5px 7px;
  }
`

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ChatBubbleLeft = styled.div`
  background-color: #e1ffc7;
  border-radius: 10px;
  padding: 10px;
  align-self: flex-start;
  max-width: 81%;
  width: 100%;
`

const Text = styled.p`
  margin: 0;
  font-size: 16px;
`

const Timestamp = styled.p`
  font-size: 12px;
  color: #555;
  margin-top: 5px;
  text-align: right;
`

const ErrorMessage = styled.div`
  color: #888;
  font-size: 18px;
  text-align: center;
  font-style: italic;
  margin-top: 20px;
`

const ReferralItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #ddd;
`
