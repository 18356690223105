import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useAuthState from '../../stores/auth';
import {
  Container,
  Heading,
  UploadSection,
  HiddenInput,
  UploadBtn,
  InputContainer,
  Input,
  Button,
  CardContainer,
  Card,
  CardTitle,
  CardText,
  DeleteIcon,
  NoDocumentsMessage,
  DownloadLink,
  PreviewLink
} from '../userSettings/DocumentsStyles';
import { FaFileUpload, FaTrash } from 'react-icons/fa';

const DocumentPage = () => {
  const { user } = useAuthState();
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState('');
  const [comment, setComment] = useState('');
  const [documents, setDocuments] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const userId = user.id;

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/uploads/documents`, {
          params: { userId },
        });

        const validDocuments = response.data.filter(doc => doc.url); // Filter out documents with missing URLs
        setDocuments(validDocuments);
      } catch (error) {
        console.error('Error fetching documents:', error);
        alert('There was an issue fetching your documents. Please try again later.');
      }
    };

    if (userId) {
      fetchDocuments();
    }
  }, [userId]);

  const onFileChange = e => {
    setFile(e.target.files[0]);
  };

  const onUpload = async () => {
    if (!description || !comment || !file) {
      alert('Please fill out all required fields and select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);
    formData.append('comment', comment);
    formData.append('userId', userId);

    setIsProcessing(true);

    try {
      await axios.post(`${process.env.GATSBY_API_URL}/uploads`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const response = await axios.get(`${process.env.GATSBY_API_URL}/uploads/documents`, {
        params: { userId },
      });
      setDocuments(response.data);

      setFile(null);
      setDescription('');
      setComment('');
    } catch (error) {
      console.error('Error uploading document:', error);
      alert('Error uploading document');
    } finally {
      setIsProcessing(false);
    }
  };

  const onDelete = async documentId => {
    try {
      const response = await axios.delete(`${process.env.GATSBY_API_URL}/uploads/${userId}/${documentId}`);

      if (response.status === 200) {
        alert('Document deleted successfully');
        setDocuments(documents.filter(doc => doc._id !== documentId));
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      alert('Error deleting document');
    }
  };

  const getDocumentType = (mimeType, filename) => {
    if (mimeType) {
      if (mimeType.startsWith('image/')) {
        return 'Image';
      } else if (mimeType.startsWith('video/')) {
        return 'Video';
      } else if (mimeType.startsWith('audio/')) {
        return 'Audio';
      } else if (mimeType === 'application/pdf') {
        return 'PDF Document';
      } else if (
        mimeType.startsWith('application/vnd') ||
        mimeType.startsWith('application/msword') ||
        mimeType.startsWith('application/vnd.openxmlformats-officedocument')
      ) {
        return 'Document';
      } else if (mimeType.startsWith('text/')) {
        return 'Text File';
      } else {
        return 'File';
      }
    }

    if (filename) {
      const extension = filename.split('.').pop().toLowerCase();
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
      const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'];
      const audioExtensions = ['mp3', 'wav', 'aac', 'flac', 'ogg'];
      const documentExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'odt'];

      if (imageExtensions.includes(extension)) {
        return 'Image';
      } else if (videoExtensions.includes(extension)) {
        return 'Video';
      } else if (audioExtensions.includes(extension)) {
        return 'Audio';
      } else if (documentExtensions.includes(extension)) {
        if (extension === 'pdf') {
          return 'PDF Document';
        }
        return 'Document';
      } else {
        return 'File';
      }
    }

    return 'Unknown';
  };

  const handleDownload = (url, filename) => {
    if (!url) {
      console.error('Download URL is missing');
      return;
    }

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleReupload = async (doc) => {
    if (!doc.url) {
      alert('This document is missing or corrupted. Please re-upload it.');
    }
  };

  return (
    <Container>
      <Heading>Your Documents</Heading>

      <UploadSection>
        <h4>Upload Document</h4>
        <HiddenInput
          type="file"
          id="fileInput"
          accept=".pdf,image/*,video/*,audio/*,.txt,.doc,.docx,.xls,.xlsx,.ppt,.pptx,application/zip"
          onChange={onFileChange}
        />
        <UploadBtn onClick={() => document.getElementById('fileInput').click()}>
          <FaFileUpload style={{ display: 'inline', marginRight: '8px' }} />{' '}
          Choose File
        </UploadBtn>
        {file && <p>Selected File: {file.name}</p>}
        <InputContainer>
          <Input
            type="text"
            placeholder="Description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Comment"
            value={comment}
            onChange={e => setComment(e.target.value)}
            required
          />
        </InputContainer>

        <Button onClick={onUpload} disabled={isProcessing}>
          {isProcessing ? 'Uploading...' : 'Upload Document'}
        </Button>
      </UploadSection>

      <div>
        <h4>Documents List</h4>
        {documents.length === 0 ? (
          <NoDocumentsMessage>No documents available.</NoDocumentsMessage>
        ) : (
          <CardContainer>
            {documents.map(doc => (
              doc.url ? (
                <Card key={doc._id}>
                  <CardTitle>{doc.filename}</CardTitle>
                  <CardText>
                    <strong>Type:</strong> {getDocumentType(doc.mimeType, doc.filename)}
                  </CardText>
                  <CardText>
                    <strong>Description:</strong> {doc.description}
                  </CardText>
                  <CardText>
                    <strong>Date:</strong> {new Date(doc.date).toLocaleDateString()}
                  </CardText>
                  <CardText>
                    <strong>Comment:</strong> {doc.comment}
                  </CardText>
                  <CardText>
                    <DownloadLink 
                      target="_blank"
                      onClick={() => handleDownload(doc.url, doc.filename)}
                    >
                      Download
                    </DownloadLink>
                    <PreviewLink
                      href={doc.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </PreviewLink>
                  </CardText>
                  <DeleteIcon onClick={() => onDelete(doc._id)}>
                    <FaTrash />
                  </DeleteIcon>
                </Card>
              ) : (
                <Card key={doc._id}>
                  <CardTitle>{doc.filename} (Unavailable)</CardTitle>
                  <CardText>
                    <strong>Error:</strong> This document is missing or corrupted.
                  </CardText>
                  <Button onClick={() => handleReupload(doc)}>Re-upload</Button>
                  <DeleteIcon onClick={() => onDelete(doc._id)}>
                    <FaTrash />
                  </DeleteIcon>
                </Card>
              )
            ))}
          </CardContainer>
        )}
      </div>
    </Container>
  );
};

export default DocumentPage;
