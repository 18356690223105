import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
  Container,
  OrderTabWrapper,
  OrderTabInfo,
  OrderTabProduct2,
  ProductDetail2,
  Details2,
  ProductName,
  ProductId,
  ProductSize,
  SetReminderButton,
} from "../../style/cartstyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { format } from "date-fns";
import useAuthState from "../../stores/auth";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', 
  maxWidth: 600, 
  backgroundColor: '#fff',
  border: '2px solid #000',
  boxShadow: 24,
  padding: 20,
  boxSizing: 'border-box',
  borderRadius: 10, 
};

export default function MedsTab() {
  const [orders, setOrders] = useState([]);
  const [pres, setPres] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); 
  const [currentTab, setCurrentTab] = useState(1);
  const [reminders, setReminders] = useState([{ time: "", repeat: "" }]);
  const [open, setOpen] = useState(false);
  const [selectedMedicationId, setSelectedMedicationId] = useState(null);

  const { user } = useAuthState();

  useEffect(() => {
    // console.log('User state updated:', user);
    if (user && Object.keys(user).length !== 0) {
      fetchOrders();
      fetchPres();
    }
  }, [user]);

  const fetchOrders = async () => {
    try {
      // console.log('Fetching orders for user:', user.id);
      const result = await axios.get(`${process.env.GATSBY_API_URL}/users/${user.id}/orders`);
      // console.log('Orders fetched successfully:', result.data.data);
      setOrders(result.data.data);
    } catch (err) {
      // console.error('Failed to fetch orders:', err);
      setError("Failed to fetch orders");
    }
  };

  const fetchPres = async () => {
    try {
      // console.log('Fetching prescriptions for user:', user.id);
      const result = await axios.get(`${process.env.GATSBY_API_URL}/users/${user.id}/prescriptions`);
      // console.log('Prescriptions fetched successfully:', result.data.data);
      setPres(result.data.data);
    } catch (err) {
      // console.error('Failed to fetch prescriptions:', err);
      setError("Failed to fetch prescriptions");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    // console.log('Tab changed to:', newValue);
    setCurrentTab(newValue);
  };

  const getCustomBrandName = (item) => {
    return `${item.product.productName} ${
      item.product.formulation !== "not applicable" ? item.product.formulation : ""
    } ${
      item.product.strength !== "not applicable" ? item.product.strength : ""
    } ${item.product.packSize}`;
  };

  const addReminderTime = () => {
    // console.log('Adding a new reminder');
    setReminders([...reminders, { time: "", repeat: "" }]);
  };

  const handleReminderChange = (index, field, value) => {
    // console.log(`Reminder ${index} ${field} changed to:`, value);
    const newReminders = reminders.map((reminder, i) =>
      i === index ? { ...reminder, [field]: value } : reminder
    );
    setReminders(newReminders);
  };

  const handleSetReminder = async () => {
    // console.log('Setting reminders:', reminders);
    if (reminders.length === 0 || !reminders[0].time) {
      // console.log('Error: Missing reminder time');
      setError("Please specify a reminder time.");
      return;
    }
  
    const reminderTime = new Date(reminders[0].time).toISOString(); 
    const repeat = reminders[0].repeat; 
  
    try {
      console.log('Sending reminder request:', {
        userId: user.id,
        medicationId: selectedMedicationId,
        reminderTime, 
        repeat, 
      });
      await axios.post(`${process.env.GATSBY_API_URL}/reminderRoutes/set-reminder`, {
        userId: user.id,
        medicationId: selectedMedicationId,
        reminderTime, 
        repeat, 
      });
  
      alert("Reminder set successfully!");
      setError("");
      setOpen(false); 
    } catch (err) {
      console.error('Failed to set reminder:', err);
      setError("Failed to set reminder.");
    }
  };
  

  const openReminderModal = (medicationId) => {
    console.log('Opening reminder modal for medication ID:', medicationId);
    setSelectedMedicationId(medicationId);
    setOpen(true);
  };

  const closeReminderModal = () => {
    console.log('Closing reminder modal');
    setOpen(false);
    setSelectedMedicationId(null);
  };

  return (
    <Container>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
      >
        <Tab label="Antibiotics" key="Antibiotics" />
        <Tab label="All medication" key="all" />
      </Tabs>

      {loading && (
        <div
          style={{
            border: "2px solid green",
            borderRadius: "10%",
            width: "100px",
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "10px",
          }}
        >
          Loading
        </div>
      )}

      {error && <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>}

      <Modal
        open={open}
        onClose={closeReminderModal}
        aria-labelledby="set-reminder-modal"
        aria-describedby="set-reminder-description"
      >
        <div style={modalStyle}>
          <h2 id="set-reminder-modal">Set Reminder</h2>
          {reminders.map((reminder, index) => (
            <div key={index} style={{ marginBottom: "15px" }}>
              <TextField
                label="Reminder Time"
                type="datetime-local"
                value={reminder.time}
                onChange={(e) => handleReminderChange(index, "time", e.target.value)}
                InputLabelProps={{ shrink: true }}
                style={{ marginRight: "10px" }}
                fullWidth
              />
              <TextField
                select
                label="Repeat"
                value={reminder.repeat}
                onChange={(e) => handleReminderChange(index, "repeat", e.target.value)}
                SelectProps={{ native: true }}
                fullWidth
              >
                <option value=""></option>
                <option value="daily">Every Day</option>
                <option value="weekly">Every Week</option>
                <option value="monthly">Every Month</option>
              </TextField>
            </div>
          ))}
          <Button variant="contained" color="primary" onClick={addReminderTime}>
            Add Another Reminder
          </Button>
          <div style={{ 
            marginTop: "20px", 
            display: "flex", 
            flexDirection: "column", 
            alignItems: "flex-end", 
            gap: "10px" 
          }}>
            <Button variant="outlined" onClick={closeReminderModal} style={{ flexShrink: 0 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSetReminder} style={{ flexShrink: 0 }}>
              Set Reminders
            </Button>
          </div>
        </div>
      </Modal>

      <OrderTabWrapper>
        <OrderTabInfo>
          {currentTab === 0 && (
            <OrderTabProduct2>
              {orders.length === 0 && <div>No records found</div>}
              {orders.map((order, index) => (
                <ProductDetail2 key={index}>
                  {order.processed &&
                    order.transactionId &&
                    order.orderStatus === "received" &&
                    order.product.category.includes("antibiotics") && (
                      <Details2>
                        <ProductName>
                          <b>Product: </b> {getCustomBrandName(order)}
                        </ProductName>
                        <ProductId>
                          <b>Generic Name:</b> {order.product.genericName}
                        </ProductId>

                        <ProductSize>
                          <b>How to use it:</b>{" "}
                          {order.product.comment || "Your Doctor to indicate"}{" "}
                          <br />
                          <b>Why use it:</b>{" "}
                          {order.diagnosis || "Your Doctor to indicate"}
                          <br />
                          <b>Date Dispensed: </b>{" "}
                          {format(new Date(order.updatedAt), "yyyy-MM-dd hh:mm aa")}
                          <br />
                          <b>Date Started: </b>{" "}
                          {format(new Date(order.updatedAt), "yyyy-MM-dd hh:mm aa")}
                          <br />
                          <b>Date Stopped: </b>
                        </ProductSize>

                        <SetReminderButton
                          onClick={() => openReminderModal(order.product._id)}
                        >
                          Set Reminder
                        </SetReminderButton>
                      </Details2>
                    )}
                </ProductDetail2>
              ))}
            </OrderTabProduct2>
          )}
          {currentTab === 1 && (
            <OrderTabProduct2>
              {orders.length === 0 && <div>No records found</div>}
              {orders.map((order, index) => (
                <ProductDetail2 key={index}>
                  {order.processed &&
                    order.transactionId &&
                    order.orderStatus === "received" && (
                      <Details2>
                        <ProductName>
                          <b>Product: </b> {getCustomBrandName(order)}
                        </ProductName>
                        <ProductId>
                          <b>Generic Name:</b> {order.product.genericName}
                        </ProductId>

                        <ProductSize>
                          <b>How to use it:</b>{" "}
                          {order.product.comment || "Your Doctor to indicate"}{" "}
                          <br />
                          <b>Why use it:</b>{" "}
                          {order.diagnosis || "Your Doctor to indicate"}
                          <br />
                          <b>Date Dispensed: </b>{" "}
                          {format(new Date(order.updatedAt), "yyyy-MM-dd hh:mm aa")}
                          <br />
                          <b>Date Started: </b>{" "}
                          {format(new Date(order.updatedAt), "yyyy-MM-dd hh:mm aa")}
                          <br />
                          <b>Date Stopped: </b>
                        </ProductSize>

                        <SetReminderButton
                          onClick={() => openReminderModal(order.product._id)}
                        >
                          Set Reminder
                        </SetReminderButton>
                      </Details2>
                    )}
                </ProductDetail2>
              ))}
            </OrderTabProduct2>
          )}
        </OrderTabInfo>
      </OrderTabWrapper>
    </Container>
  );
}


