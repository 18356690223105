import React, { useState } from "react"
import styled from "styled-components"
import useAuthState from "../../stores/auth"

const Container = styled.div`
  min-height: 550px;
  // background-image: url("https://images.pexels.com/photos/747964/pexels-photo-747964.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260");
  // background-size: cover;
  // background-position: center bottom;
  background-color: #1d9682;
  border-radius: 0 0 30px 30px;
  font-family: oswald;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding-bottom: 8em;
`
const TextContainer = styled.div`
  //backdrop-filter: blur(3px);
  border-radius: 7px;
  padding: 20px;
`
const Heading = styled.h1`
  font-size: 4em;
`
const UserDesc = styled.p`
  font-size: 1.5em;
  font-weight: 300;
`

function UserHeader() {
  const { user } = useAuthState()

  const [firstName, setFirstName] = useState(user?.firstName || "")

  return (
    <>
      <Container>
        <TextContainer>
          <Heading>Hello {firstName} </Heading>
          <UserDesc>
            This is your profile page.You can update your profile details <br />
            ,view your current medication, your upcoming Appointments, Progress
            Notes, record and monitor your vitals.
          </UserDesc>

          <span style={{ paddingTop: "20px", fontWeight: "200" }}>
            scroll below to view and update your profile and tabs
          </span>
        </TextContainer>
      </Container>
    </>
  )
}

export default UserHeader
