import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TextField from "@material-ui/core/TextField"
import DeleteIcon from "@material-ui/icons/Delete"
import TableHead from "@material-ui/core/TableHead"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import React from "react"
import { useState } from "react"
import { FindHeader } from "../findForm/formElements"
import "charts.css"
import axios from "axios"
import { format } from "date-fns"
import { Button } from "@material-ui/core"
import { FaArrowDown, FaArrowUp, FaCheck, FaInfoCircle } from "react-icons/fa"
import * as XLSX from "xlsx"
import { IoMdDownload } from "react-icons/io"
import { Link } from "gatsby"
import {
  AfyaCard,
  AfyaContainer,
  AfyaForm,
  AfyaInput,
  InnerContainer,
  UpdateBtn,
  TabContainer,
} from "./afyaTabElements"
import Analytics from "./analytics"
import useAuthState from "../../stores/auth"

export default function AfyaTab() {
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState("")
  const [height, setHeight] = useState("")
  const [weight, setWeight] = useState("")
  const [systolic, setSystolic] = useState("")
  const [diastolic, setDiastolic] = useState("")
  const [oxygen, setOxygen] = useState("")
  const [pulse, setPulse] = useState("")
  const [randomBloodSugar, setRandomBloodSugar] = useState("")
  const [fastingBloodSugar, setFastingBloodSugar] = useState("")
  const [hbA1c, setHbA1c] = useState("")
  const [ldl, setLdl] = useState("")
  const [hdl, setHdl] = useState("")
  const [respiration, setRespiration] = useState("")
  const [creatinine, setCreatinine] = useState("")
  const [eGFR, setEGFR] = useState("")
  const [inr, setInr] = useState("")
  const [comment, setComment] = useState("")
  const [vitals, setVitals] = useState([])

  const [currentTab, setCurrentTab] = useState(0)
  const [currentTabRecord, setCurrentTabRecord] = useState(0)
  const [currentTabReview, setCurrentTabReview] = useState(0)

  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 30)
  )
  const [endDate, setEndDate] = useState(new Date())
  const [dateRange, setDateRange] = useState("thirtydays")

  const handleStartDateChange = date => {
    console.log("startdatehs",date)
    setStartDate(date)
  }

  const handleEndDateChange = date => {
    console.log("enddatehe",date)
    setEndDate(date)
  }

  console.log("startdatenew",new Date(startDate).toISOString())
  console.log("startdatenew",new Date(startDate).toUTCString())
  console.log("endDatenew",new Date(endDate).toISOString())

  const handleDateRangeClick = range => () => {
    setDateRange(range)

    switch (range) {
      case "custom":
        setStartDate("")
        setEndDate("")
        break
      case "oneday":
        setStartDate(new Date())
        setEndDate(new Date())
        break
      case "sevendays":
        setStartDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
        setEndDate(new Date())
        break
      case "thirtydays":
        setStartDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
        setEndDate(new Date())
        break
    }
  }

  const [showAdvice, setShowAdvice] = useState(false)

  const handleAdviceClick = () => {
    setShowAdvice(true)
  }

  const handleCloseAdvice = () => {
    setShowAdvice(false)
  }

  const lowBSAdvice = [
    "Have any of these",
    "½cup of juice or regular soda",
    "1 tablespoon of sugar, honey, or syrup",
    "3-4 glucose tablets",
    "Wait for 15mins and then retest your sugars",
  ]

  const highBSAdvice = [
    "Drink more water",
    "Exercise, walking is a good way of achieving this; aim for at least 30mins",
    "Low carb diet, plenty of fiber rich foods",
    "Reduce sugary beverages",
    "Reduce stress",
    "Stop smoking",
    "Loose exta weight",
  ]

  const highBPAdvice = [
    "Loose weight and watch your waistline",
    "Exercise, aim for at least 30 minutes of moderate physical activity every day",
    "Eat a diet rich in whole grains, fruits, vegetables and low-fat dairy products",
    "Reduce salt (sodium) in your diet",
    "Limit alcohol",
    "Quit smoking",
    "Get a good night's sleep, 6 hours or more",
    "Reduce stress; reframe your mindset, avoid stress triggers, practice gratitude, take time to relax and enjoy",
  ]

  const lowBPAdvice = [
    "Drink more water, less alcohol",
    "Pay attention to body positions. Gently move from lying flat or squatting to a standing position",
    "Eat a balanced diet",
    "Eat smaller meals",
    "Get regular exercise to promote blood flow",
  ]

  const RangeIndicator = ({
    value,
    min,
    max,
    test,
    adviceLowBP,
    adviceHighBP,
  }) => {
    if (value < min) {
      return (
        <>
          <FaArrowDown style={{ color: "blue" }} />
          <span
            style={{ color: "blue", textAlign: "center" }}
          >{`Low ${test} below (${min})`}</span>
          {adviceLowBP && (
            <button
              onClick={handleAdviceClick}
              style={{ background: "white", border: "none" }}
            >
              <FaInfoCircle style={{ color: "blue" }} title={`What to do`} />
            </button>
          )}
          {showAdvice && (
            <div>
              {adviceLowBP}
              <button
                onClick={handleCloseAdvice}
                style={{
                  background: "blue",
                  borderRadius: "5px",
                  color: "white",
                  width: "3rem",
                  border: "none",
                }}
              >
                X
              </button>
            </div>
          )}
        </>
      )
    } else if (value > max) {
      return (
        <>
          <FaArrowUp style={{ color: "red" }} />
          <span
            style={{ color: "red", textAlign: "center" }}
          >{`High ${test} above (${max})`}</span>
          {adviceHighBP && (
            <button
              onClick={handleAdviceClick}
              style={{ background: "white", border: "none" }}
            >
              <FaInfoCircle style={{ color: "blue" }} title={`What to do`} />
            </button>
          )}
          {showAdvice && (
            <div>
              {adviceHighBP}
              <button
                onClick={handleCloseAdvice}
                style={{
                  background: "blue",
                  borderRadius: "5px",
                  color: "white",
                  width: "3rem",
                  border: "none",
                }}
              >
                X
              </button>
            </div>
          )}
        </>
      )
    } else {
      return (
        <>
          <FaCheck style={{ color: "green" }} />{" "}
          <span
            style={{ color: "green", textAlign: "center" }}
          >{`Normal ${test}`}</span>
        </>
      )
    }
  }

  const RangeIndicatorDiabetes = ({
    value,
    min,
    max,
    test,
    adviceLowBS,
    adviceHighBS,
  }) => {
    if (value < min) {
      return (
        <>
          <FaArrowDown style={{ color: "blue" }} />
          <span
            style={{ color: "blue", textAlign: "center" }}
          >{`Low ${test} below (${min})`}</span>
          {adviceLowBS && (
            <button
              onClick={handleAdviceClick}
              style={{ background: "white", border: "none" }}
            >
              <FaInfoCircle style={{ color: "blue" }} title={`What to do`} />
            </button>
          )}
          {showAdvice && (
            <div>
              {adviceLowBS}
              <button
                onClick={handleCloseAdvice}
                style={{
                  background: "blue",
                  borderRadius: "5px",
                  color: "white",
                  width: "3rem",
                  border: "none",
                }}
              >
                X
              </button>
            </div>
          )}
        </>
      )
    } else if (value > max) {
      return (
        <>
          <FaArrowUp style={{ color: "red" }} />
          <span
            style={{ color: "red", textAlign: "center" }}
          >{`High ${test} above (${max})`}</span>
          {adviceHighBS && (
            <button
              onClick={handleAdviceClick}
              style={{ background: "white", border: "none" }}
            >
              <FaInfoCircle style={{ color: "blue" }} title={`What to do`} />
            </button>
          )}
          {showAdvice && (
            <div>
              {adviceHighBS}
              <button
                onClick={handleCloseAdvice}
                style={{
                  background: "blue",
                  borderRadius: "5px",
                  color: "white",
                  width: "3rem",
                  border: "none",
                }}
              >
                X
              </button>
            </div>
          )}
        </>
      )
    } else {
      return (
        <>
          <FaCheck style={{ color: "green" }} />{" "}
          <span
            style={{ color: "green", textAlign: "center" }}
          >{`Normal ${test}`}</span>
        </>
      )
    }
  }

  const RangeIndicatorRead = ({ value, min, max }) => {
    if (value < min) {
      return <FaArrowDown style={{ color: "blue" }} />
    } else if (value > max) {
      return <FaArrowUp style={{ color: "red" }} />
    } else {
      return <FaCheck style={{ color: "green" }} />
    }
  }

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const handleChangeRecord = (event, newValue) => {
    setCurrentTabRecord(newValue)
  }

  const handleChangeReview = (event, newValue) => {
    setCurrentTabReview(newValue)
  }

  const { user } = useAuthState()

  const fetchVitals = async () => {
    const result = await axios.get(
      `${process.env.GATSBY_API_URL}/users/${user.id}/vitals`
    )
    //console.log("result.data",result.data)
    if (JSON.stringify(result.data) !== JSON.stringify(vitals)) {
      setVitals(result.data)
    }

    // setVitals(result.data.data)
  }

  const filteredData = vitals?.filter(item => {
    if (dateRange === "custom") {
      return startDate && endDate
        ? new Date(item.updatedAt) >= new Date(startDate) &&
            new Date(item.updatedAt) <=
              new Date(endDate).setDate(new Date(endDate).getDate() + 1)
        : true
    } else {
      const rangeStartDate = new Date()
      const rangeEndDate = new Date()

      switch (dateRange) {
        case "oneday":
          rangeStartDate.setDate(rangeStartDate.getDate() - 1)
          break
        case "sevendays":
          rangeStartDate.setDate(rangeStartDate.getDate() - 7)
          break
        case "thirtydays":
          rangeStartDate.setDate(rangeStartDate.getDate() - 30)
          break
      }

      return (
        new Date(item.updatedAt) >= rangeStartDate &&
        new Date(item.updatedAt) <= rangeEndDate
      )
    }
  })

  //console.log("filteredata",filteredData)

  const downloadExcel = data => {
    // Convert data to Excel format
    const workbook = XLSX.utils.book_new()
    const sheetData = [
      Object.keys(data[0]),
      ...data.map(row => Object.values(row)),
    ]
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData)
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    })
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })

    // Download the file
    const link = document.createElement("a")
    link.href = window.URL.createObjectURL(blob)
    link.download = "table.xlsx"
    link.click()
  }

  React.useEffect(() => {
    if (user && Object.keys(user).length !== 0) {
   // if (user) {
      fetchVitals()
    }
  }, [user, vitals])

  const handleVitals = async () => {
    setError("")
    setSuccess("")
    //error handling and validation for .....x
    try {
      setIsLoading(true)
      await axios.post(`${process.env.GATSBY_API_URL}/users/${user.id}/vitals`, {
        height,
        weight,
        systolic,
        diastolic,
        oxygen,
        pulse,
        randomBloodSugar,
        fastingBloodSugar,
        hbA1c,
        ldl,
        hdl,
        respiration,
        creatinine,
        eGFR,
        inr,
        comment,
        //patientId: user.id,
      })
      setIsLoading(false)
      setSuccess("Successfully updated vital")
      fetchVitals()
      setHeight("")
      setWeight("")
      setSystolic("")
      setDiastolic("")
      setOxygen("")
      setPulse("")
      setRandomBloodSugar("")
      setFastingBloodSugar("")
      setHbA1c("")
      setLdl("")
      setHdl("")
      setRespiration("")
      setCreatinine("")
      setEGFR("")
      setInr("")
      setComment("")
    } catch (error) {
      setIsLoading(false)
      const message = error.response.data.message
      setError(message)
    }
  }

  const deleteVital = async (vitalsId) => {
    console.log("id",vitalsId)
    setError("")
    setSuccess("")
    try {
      setIsLoading(true)
     await axios.delete(`${process.env.GATSBY_API_URL}/users/${user.id}/vitals/${vitalsId}`)
      setIsLoading(false)
      setSuccess("Successfully deleted vital")
      fetchVitals()
    } catch (err) {
      setIsLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  return (
    <TabContainer>
      <div style={{ width: "95%", display: "flex", justifyContent: "center", marginTop: "25px" }}>
        <Tabs
          value={currentTabRecord}
          onChange={handleChangeRecord}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab label="General" key="general" />
          <Tab label="Diabetes" key="diabetes" />
          <Tab label="Hypertension" key="hypertension" />
        </Tabs>
      </div>
      <AfyaContainer>
        {currentTabRecord === 0 && (
          <AfyaCard>
            <FindHeader style={{ width: "100%" }}>
              <span>Record your vitals:</span>
            </FindHeader>
            <AfyaForm>
              <InnerContainer>
                <Grid container spacing={5} style={{ width: "100%" }}>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="height"
                      name="height"
                      label="Your Height in cm"
                      fullWidth
                      //autoComplete="height"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={height}
                      onChange={e => setHeight(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="weight"
                      name="weight"
                      label="Your Weight in kgs"
                      fullWidth
                      //autoComplete="weight"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={weight}
                      onChange={e => setWeight(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Systolic BP"
                      name="Systolic BP"
                      label="Systolic Blood Pressure"
                      fullWidth
                      //autoComplete="Systolic BP"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={systolic}
                      onChange={e => setSystolic(e.target.value)}
                    />
                    {systolic && (
                      <RangeIndicator
                        value={systolic}
                        min={90}
                        max={120}
                        test={"BP"}
                        adviceLowBP={lowBPAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                        adviceHighBP={highBPAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Diastolic BP"
                      name="Diastolic BP"
                      label="Diastolic Blood Pressure"
                      fullWidth
                      //autoComplete="Diastolic BP"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={diastolic}
                      onChange={e => setDiastolic(e.target.value)}
                    />
                    {diastolic && (
                      <RangeIndicator
                        value={diastolic}
                        min={60}
                        max={80}
                        test={"BP"}
                        adviceLowBP={lowBPAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                        adviceHighBP={highBPAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Oxygen Saturation level"
                      name="Oxygen Saturation level"
                      label="Oxygen Saturation level %"
                      fullWidth
                      //autoComplete="Oxygen Saturation level"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={oxygen}
                      onChange={e => setOxygen(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Pulse"
                      name="Pulse"
                      label="Your Bpm(Pulse Rate)"
                      fullWidth
                      //autoComplete="Pulse"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={pulse}
                      onChange={e => setPulse(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Random Blood Sugar"
                      name="Random Blood Sugar"
                      label="Random Glucose(mmol/L)"
                      fullWidth
                      //autoComplete="Random Blood Sugar"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={randomBloodSugar}
                      onChange={e => setRandomBloodSugar(e.target.value)}
                    />
                    {randomBloodSugar && (
                      <RangeIndicatorDiabetes
                        value={randomBloodSugar}
                        min={3.9}
                        max={11.1}
                        test={"Blood sugar"}
                        adviceLowBS={lowBSAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                        adviceHighBS={highBSAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Fasting Blood Sugar"
                      name="Fasting Blood Sugar"
                      label="Fasting Glucose(mmol/L)"
                      fullWidth
                      //autoComplete="Fasting Blood Sugar"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={fastingBloodSugar}
                      onChange={e => setFastingBloodSugar(e.target.value)}
                    />
                    {fastingBloodSugar && (
                      <RangeIndicatorDiabetes
                        value={fastingBloodSugar}
                        min={2.8}
                        max={5.6}
                        test={"Blood sugar"}
                        adviceLowBS={lowBSAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                        adviceHighBS={highBSAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="LDL"
                      name="LDL"
                      label="LDL Cholesterol mg/dL"
                      fullWidth
                      //autoComplete="LDL"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={ldl}
                      onChange={e => setLdl(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="HDL"
                      name="HDL"
                      label="HDL Cholesterol mg/dL"
                      fullWidth
                      //autoComplete="HDL"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={hdl}
                      onChange={e => setHdl(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Respiration rate"
                      name="Respiration rate"
                      label="Respiration rate"
                      fullWidth
                      //autoComplete="Respiration rate"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={respiration}
                      onChange={e => setRespiration(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Serum Creatinine"
                      name="Serum Creatinine"
                      label="Serum Creatinine mmol/L"
                      fullWidth
                      //autoComplete="Serum Creatinine"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={creatinine}
                      onChange={e => setCreatinine(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="eGFR"
                      name="eGFR"
                      label="Glomerular Filtration Rate"
                      fullWidth
                      //autoComplete="eGFR"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={eGFR}
                      onChange={e => setEGFR(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="inr"
                      name="inr"
                      label="Prothrombin Time INR"
                      fullWidth
                      //autoComplete="inr"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={inr}
                      onChange={e => setInr(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="comment"
                      name="comment"
                      label="Comment"
                      fullWidth
                      //autoComplete="comment"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={comment}
                      onChange={e => setComment(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </InnerContainer>
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                {error}
              </p>
              <p
                style={{
                  color: "green",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                {success}
              </p>
              <p
                style={{
                  color: "green",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                {isLoading && "Loading..."}
              </p>
              {user ? (
                <UpdateBtn onClick={e => handleVitals() && e.preventDefault()}>
                  Update
                </UpdateBtn>
              ) : (
                <UpdateBtn>
                  <Link css={{ textDecoration: "none" }} to="/Login">
                    Login
                  </Link>
                </UpdateBtn>
              )}
            </AfyaForm>
          </AfyaCard>
        )}
        {currentTabRecord === 1 && (
          <AfyaCard>
            <FindHeader style={{ width: "100%" }}>
              <span>Record your vitals:</span>
            </FindHeader>
            <AfyaForm>
              <InnerContainer>
                <Grid container spacing={5} style={{ width: "100%" }}>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Random Blood Sugar"
                      name="Random Blood Sugar"
                      label="Random Glucose(mmol/L)"
                      fullWidth
                      //autoComplete="Random Blood Sugar"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={randomBloodSugar}
                      onChange={e => setRandomBloodSugar(e.target.value)}
                    />
                    {randomBloodSugar && (
                      <RangeIndicatorDiabetes
                        value={randomBloodSugar}
                        min={3.9}
                        max={11.1}
                        test={"Blood sugar"}
                        adviceLowBS={lowBSAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                        adviceHighBS={highBSAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Fasting Blood Sugar"
                      name="Fasting Blood Sugar"
                      label="Fasting Glucose(mmol/L)"
                      fullWidth
                      //autoComplete="Fasting Blood Sugar"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={fastingBloodSugar}
                      onChange={e => setFastingBloodSugar(e.target.value)}
                    />
                    {fastingBloodSugar && (
                      <RangeIndicatorDiabetes
                        value={fastingBloodSugar}
                        min={2.8}
                        max={5.6}
                        test={"Blood sugar"}
                        adviceLowBS={lowBSAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                        adviceHighBS={highBSAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="HbA1c"
                      name="HbA1c"
                      label="HbA1c %"
                      fullWidth
                      //autoComplete="HbA1c"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={hbA1c}
                      onChange={e => setHbA1c(e.target.value)}
                    />
                    {hbA1c && (
                      <RangeIndicatorDiabetes
                        value={hbA1c}
                        min={4}
                        max={5.7}
                        test={"Blood sugar"}
                        adviceLowBS={lowBSAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                        adviceHighBS={highBSAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="LDL"
                      name="LDL"
                      label="LDL Cholesterol mg/dL"
                      fullWidth
                      //autoComplete="LDL"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={ldl}
                      onChange={e => setLdl(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="HDL"
                      name="HDL"
                      label="HDL Cholesterol mg/dL"
                      fullWidth
                      //autoComplete="HDL"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={hdl}
                      onChange={e => setHdl(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Serum Creatinine"
                      name="Serum Creatinine"
                      label="Serum Creatinine mmol/L"
                      fullWidth
                      //autoComplete="Serum Creatinine"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={creatinine}
                      onChange={e => setCreatinine(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="eGFR"
                      name="eGFR"
                      label="Glomerular Filtration Rate"
                      fullWidth
                      //autoComplete="eGFR"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={eGFR}
                      onChange={e => setEGFR(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="comment"
                      name="comment"
                      label="Comment"
                      fullWidth
                      //autoComplete="comment"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={comment}
                      onChange={e => setComment(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </InnerContainer>
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                {error}
              </p>
              <p
                style={{
                  color: "green",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                {success}
              </p>
              <p
                style={{
                  color: "green",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                {isLoading && "Loading..."}
              </p>
              {user ? (
                <UpdateBtn onClick={e => handleVitals() && e.preventDefault()}>
                  Update
                </UpdateBtn>
              ) : (
                <UpdateBtn>
                  <Link css={{ textDecoration: "none" }} to="/Login">
                    Login
                  </Link>
                </UpdateBtn>
              )}
            </AfyaForm>
          </AfyaCard>
        )}
        {currentTabRecord === 2 && (
          <AfyaCard>
            <FindHeader style={{ width: "100%" }}>
              <span>Record your vitals:</span>
            </FindHeader>
            <AfyaForm>
              <InnerContainer>
                <Grid container spacing={5} style={{ width: "100%" }}>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Systolic BP"
                      name="Systolic BP"
                      label="Systolic Blood Pressure"
                      fullWidth
                      //autoComplete="Systolic BP"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={systolic}
                      onChange={e => setSystolic(e.target.value)}
                    />
                    {systolic && (
                      <RangeIndicator
                        value={systolic}
                        min={90}
                        max={120}
                        test={"BP"}
                        adviceLowBP={lowBPAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                        adviceHighBP={highBPAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Diastolic BP"
                      name="Diastolic BP"
                      label="Diastolic Blood Pressure"
                      fullWidth
                      //autoComplete="Diastolic BP"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={diastolic}
                      onChange={e => setDiastolic(e.target.value)}
                    />
                    {diastolic && (
                      <RangeIndicator
                        value={diastolic}
                        min={60}
                        max={80}
                        test={"BP"}
                        adviceLowBP={lowBPAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                        adviceHighBP={highBPAdvice.map(item => (
                          <li>{item}</li>
                        ))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Pulse"
                      name="Pulse"
                      label="Your Bpm(Pulse Rate)"
                      fullWidth
                      //autoComplete="Pulse"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={pulse}
                      onChange={e => setPulse(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="LDL"
                      name="LDL"
                      label="LDL Cholesterol mg/dL"
                      fullWidth
                      //autoComplete="LDL"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={ldl}
                      onChange={e => setLdl(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="HDL"
                      name="HDL"
                      label="HDL Cholesterol mg/dL"
                      fullWidth
                      //autoComplete="HDL"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={hdl}
                      onChange={e => setHdl(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="Serum Creatinine"
                      name="Serum Creatinine"
                      label="Serum Creatinine mmol/L"
                      fullWidth
                      //autoComplete="Serum Creatinine"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={creatinine}
                      onChange={e => setCreatinine(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="eGFR"
                      name="eGFR"
                      label="Glomerular Filtration Rate"
                      fullWidth
                      //autoComplete="eGFR"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={eGFR}
                      onChange={e => setEGFR(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AfyaInput
                      id="comment"
                      name="comment"
                      label="Comment"
                      fullWidth
                      //autoComplete="comment"
                      autoComplete="off"
                      style={{ fontFamily: "Oswald" }}
                      value={comment}
                      onChange={e => setComment(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </InnerContainer>
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                {error}
              </p>
              <p
                style={{
                  color: "green",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                {success}
              </p>
              <p
                style={{
                  color: "green",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                {isLoading && "Loading..."}
              </p>
              {user ? (
                <UpdateBtn onClick={e => handleVitals() && e.preventDefault()}>
                  Update
                </UpdateBtn>
              ) : (
                <UpdateBtn>
                  <Link css={{ textDecoration: "none" }} to="/Login">
                    Login
                  </Link>
                </UpdateBtn>
              )}
            </AfyaForm>
          </AfyaCard>
        )}
      </AfyaContainer>

      {/* review table and chart */}

      <p style={{ color: "red", textAlign: "center", fontSize: "1rem" }}>
        {error}
      </p>
      <p style={{ color: "green", textAlign: "center", fontSize: "1rem" }}>
        {success}
      </p>
      <div style={{ width: "95%", display: "flex", justifyContent: "center" }}>
        <Tabs
          value={currentTabReview}
          onChange={handleChangeReview}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab label="General" key="general" />
          <Tab label="Diabetes" key="diabetes" />
          <Tab label="Hypertension" key="hypertension" />
        </Tabs>
      </div>

      {currentTabReview === 0 && (
        <>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="Table" key="table" />
            <Tab label="Charts" key="charts" />
          </Tabs>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleDateRangeClick("oneday")}>1 day</Button>
            <Button onClick={handleDateRangeClick("sevendays")}>7 days</Button>
            <Button onClick={handleDateRangeClick("thirtydays")}>
              30 days
            </Button>
            <Button onClick={handleDateRangeClick("custom")}>Custom</Button>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", margin: "1rem" }}
          >
            {dateRange === "custom" && (
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="date"
                    label="Start Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    style={{ fontFamily: "Oswald" }}
                    value={startDate}
                    onChange={e => {
                      handleStartDateChange(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="date"
                    label="End Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    style={{ fontFamily: "Oswald" }}
                    value={endDate}
                    onChange={e => {
                      handleEndDateChange(e.target.value)
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </div>

          <button
            style={{
              backgroundColor: "#007acc",
              display: "flex",
              alignItems: "center",
              fontSize: "1rem",
              color: "#fff",
              border: "none",
              padding: "5px 10px",
              marginBottom: "1rem",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => downloadExcel(filteredData)}
          >
            <IoMdDownload /> Download
          </button>
          {currentTab === 0 && (
            <Card style={{ width: "99%" }}>
              <div
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#1d9682",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1.5rem",
                }}
              >
                Track Progress
              </div>
              <TableContainer component={Paper} style={{ maxHeight: "70vh" }}>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Date{" "}
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Height cm{" "}
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Weight kgs
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Systolic BP mmHg
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Diastolic BP mmHg
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Oxygen %
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Pulse rate bpm
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Random Blood Sugar mmol/L
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Fasting Blood Sugar mmol/L
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        HbA1c %
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        LDL Cholesterol mg/dL
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        HDL Cholesterol mg/dL
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Respiration Rate bpm
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Serum Creatinine mmol/L
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Glomerular Filtration Rate
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Prothrombin Time INR
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Comment
                      </TableCell>
                      <TableCell colSpan={5} align="center"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredData &&
                      filteredData.map(row => (
                        <TableRow
                          key={row.updatedAt}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "#e5e5e5",
                            }}
                            scope="row"
                          >
                            {format(
                              new Date(row.updatedAt),
                              "yyyy-MM-dd hh:mm aa"
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.height}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.weight}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.systolic}
                            {row.systolic && (
                              <RangeIndicatorRead
                                value={row.systolic}
                                min={90}
                                max={120}
                              />
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.diastolic}
                            {row.diastolic && (
                              <RangeIndicatorRead
                                value={row.diastolic}
                                min={60}
                                max={80}
                              />
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.oxygen}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.pulse}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.randomBloodSugar}
                            {row.randomBloodSugar && (
                              <RangeIndicatorRead
                                value={row.randomBloodSugar}
                                min={3.9}
                                max={11.1}
                              />
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.fastingBloodSugar}
                            {row.fastingBloodSugar && (
                              <RangeIndicatorRead
                                value={row.fastingBloodSugar}
                                min={2.8}
                                max={5.6}
                              />
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.hbA1c}
                            {row.hbA1c && (
                              <RangeIndicatorRead
                                value={row.hbA1c}
                                min={4}
                                max={5.7}
                              />
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.ldl}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.hdl}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.respiration}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.creatinine}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.eGFR}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.inr}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.comment}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            <Button onClick={() => deleteVital(row._id)}>
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
          {currentTab === 1 &&
            (filteredData ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <Analytics
                  //height={"700px"}
                  filter={{
                    // "userId": user._id,
                    "vitals.createdAt": {
                      $gte: startDate && new Date(startDate),
                      $lte: endDate && new Date(endDate),
                    },
                  }}
                  //width={"700px"}
                  chartId={"633157de-4c44-456e-8783-d44dee67db42"}
                />
                {/* below for tripleaim */}
                {/* <Analytics
                  filter={{
                    "vitals.createdAt": {
                      $gte: startDate && new Date(startDate),
                      $lte: endDate && new Date(endDate),
                    },
                  }}
                  //chartId={"633157de-4c44-456e-8783-d44dee67db42"}
                  chartId={"cf13f37d-798d-4b9b-b072-0652ac51369d"}
                /> */}
                <Analytics
                  //height={"700px"}
                  filter={{
                    // "userId": user._id,
                    "vitals.createdAt": {
                      $gte: startDate && new Date(startDate),
                      $lte: endDate && new Date(endDate),
                    },
                  }}
                  //width={"700px"}
                  chartId={"cdd2719c-6ff6-459b-90df-f8a2c23cecaa"}
                />
                <Analytics
                  //height={"700px"}
                  filter={{
                    // "userId": user._id,
                    "vitals.createdAt": {
                      $gte: startDate && new Date(startDate),
                      $lte: endDate && new Date(endDate),
                    },
                  }}
                  //width={"700px"}
                  chartId={"c797cad3-83c9-4d2e-b681-14d8a1816783"}
                />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                Continue recording, your charts will be displayed here
              </div>
            ))}
        </>
      )}
      {currentTabReview === 1 && (
        <>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="Table" key="table" />
            <Tab label="Charts" key="charts" />
          </Tabs>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleDateRangeClick("oneday")}>1 day</Button>
            <Button onClick={handleDateRangeClick("sevendays")}>7 days</Button>
            <Button onClick={handleDateRangeClick("thirtydays")}>
              30 days
            </Button>
            <Button onClick={handleDateRangeClick("custom")}>Custom</Button>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", margin: "1rem" }}
          >
            {dateRange === "custom" && (
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="date"
                    label="Start Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    style={{ fontFamily: "Oswald" }}
                    value={startDate}
                    onChange={e => {
                      handleStartDateChange(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="date"
                    label="End Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    style={{ fontFamily: "Oswald" }}
                    value={endDate}
                    onChange={e => {
                      handleEndDateChange(e.target.value)
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </div>

          <button
            style={{
              backgroundColor: "#007acc",
              display: "flex",
              alignItems: "center",
              fontSize: "1rem",
              color: "#fff",
              border: "none",
              padding: "5px 10px",
              marginBottom: "1rem",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => downloadExcel(filteredData)}
          >
            <IoMdDownload /> Download
          </button>

          {currentTab === 0 && (
            <Card style={{ width: "99%" }}>
              <div
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#1d9682",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1.5rem",
                }}
              >
                Track Progress
              </div>

              <TableContainer component={Paper} style={{ maxHeight: "70vh" }}>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Date{" "}
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Random Blood Sugar mmol/L
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Fasting Blood Sugar mmol/L
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        HbA1c %
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        LDL Cholesterol mg/dL
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        HDL Cholesterol mg/dL
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Serum Creatinine mmol/L
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Glomerular Filtration Rate
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Prothrombin Time INR
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Comment
                      </TableCell>
                      <TableCell colSpan={5} align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData &&
                      filteredData.map(row => (
                        <TableRow
                          key={row.updatedAt}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "#e5e5e5",
                            }}
                            scope="row"
                          >
                            {format(
                              new Date(row.updatedAt),
                              "yyyy-MM-dd hh:mm aa"
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.randomBloodSugar}
                            {row.randomBloodSugar && (
                              <RangeIndicatorRead
                                value={row.randomBloodSugar}
                                min={3.9}
                                max={11.1}
                              />
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.fastingBloodSugar}
                            {row.fastingBloodSugar && (
                              <RangeIndicatorRead
                                value={row.fastingBloodSugar}
                                min={2.8}
                                max={5.6}
                              />
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.hbA1c}
                            {row.hbA1c && (
                              <RangeIndicatorRead
                                value={row.hbA1c}
                                min={4}
                                max={5.7}
                              />
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.ldl}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.hdl}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.creatinine}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.eGFR}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.inr}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.comment}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            <Button onClick={() => deleteVital(row._id)}>
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
          {currentTab === 1 &&
            (filteredData ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <Analytics
                  //height={"700px"}
                  filter={{
                    "vitals.createdAt": {
                      $gte: startDate && new Date(startDate),
                      $lte: endDate && new Date(endDate),
                    },
                  }}
                  //width={"700px"}
                  chartId={"633157de-4c44-456e-8783-d44dee67db42"}
                />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                Continue recording, your charts will be displayed here
              </div>
            ))}
        </>
      )}
      {currentTabReview === 2 && (
        <>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="Table" key="table" />
            <Tab label="Charts" key="charts" />
          </Tabs>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleDateRangeClick("oneday")}>1 day</Button>
            <Button onClick={handleDateRangeClick("sevendays")}>7 days</Button>
            <Button onClick={handleDateRangeClick("thirtydays")}>
              30 days
            </Button>
            <Button onClick={handleDateRangeClick("custom")}>Custom</Button>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", margin: "1rem" }}
          >
            {dateRange === "custom" && (
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="date"
                    label="Start Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    style={{ fontFamily: "Oswald" }}
                    value={startDate}
                    onChange={e => {
                      handleStartDateChange(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="date"
                    label="End Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    style={{ fontFamily: "Oswald" }}
                    value={endDate}
                    onChange={e => {
                      handleEndDateChange(e.target.value)
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </div>

          <button
            style={{
              backgroundColor: "#007acc",
              display: "flex",
              alignItems: "center",
              fontSize: "1rem",
              color: "#fff",
              border: "none",
              padding: "5px 10px",
              marginBottom: "1rem",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => downloadExcel(filteredData)}
          >
            <IoMdDownload /> Download
          </button>

          {currentTab === 0 && (
            <Card style={{ width: "90%" }}>
              <div
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#1d9682",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1.5rem",
                }}
              >
                Track Progress
              </div>

              <TableContainer component={Paper} style={{ maxHeight: "70vh" }}>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Date{" "}
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Systolic BP mmHg
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Diastolic BP mmHg
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Pulse rate bpm
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        LDL Cholesterol mg/dL
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        HDL Cholesterol mg/dL
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Serum Creatinine mmol/L
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Glomerular Filtration Rate
                      </TableCell>
                      <TableCell colSpan={5} align="center">
                        Comment
                      </TableCell>
                      <TableCell colSpan={5} align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData &&
                      filteredData.map(row => (
                        <TableRow
                          key={row.updatedAt}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "#e5e5e5",
                            }}
                            scope="row"
                          >
                            {format(
                              new Date(row.updatedAt),
                              "yyyy-MM-dd hh:mm aa"
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.systolic}
                            {row.systolic && (
                              <RangeIndicatorRead
                                value={row.systolic}
                                min={90}
                                max={120}
                              />
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.diastolic}
                            {row.diastolic && (
                              <RangeIndicatorRead
                                value={row.diastolic}
                                min={60}
                                max={80}
                              />
                            )}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.pulse}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.ldl}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.hdl}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.creatinine}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.eGFR}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            {row.comment}
                          </TableCell>
                          <TableCell colSpan={5} align="center">
                            <Button onClick={() => deleteVital(row._id)}>
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
          {currentTab === 1 &&
            (filteredData ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <Analytics
                  //height={"700px"}
                  filter={{
                    "vitals.createdAt": {
                      $gte: startDate && new Date(startDate),
                      $lte: endDate && new Date(endDate),
                    },
                  }}
                  //width={"700px"}
                  chartId={"cdd2719c-6ff6-459b-90df-f8a2c23cecaa"}
                />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                Continue recording, your charts will be displayed here
              </div>
            ))}
        </>
      )}
    </TabContainer>
  )
}
