import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid'; 
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';  // Import Button component
import MenuItem from '@material-ui/core/MenuItem';
import { navigate } from 'gatsby';
import { format } from 'date-fns';
import axios from 'axios';
import { Accordion } from 'react-bootstrap';
import {
  CardHeader,
  Heading,
  ProfileCard,
  SaveIcon,
  SettingsBtn,
  TopButton,
  UserContainer,
  UserWrapper,
} from './userStyles';
import kenyanCounties from '../../data/kenyanCounties';
import useAuthState from '../../stores/auth';

export default function UserForm() {
  const { user, setUser } = useAuthState();

  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
  const [gender, setGender] = useState(user?.gender || '');
  const [dob, setDob] = useState(user?.dob ? format(new Date(user.dob), 'yyyy-MM-dd') : '');
  const [county, setCounty] = useState(user?.physicalAddress?.county || '');
  const [street, setStreet] = useState(user?.physicalAddress?.street || '');
  const [allergies, setAllergies] = useState(user?.allergies || []);
  const [newAllergy, setNewAllergy] = useState(''); // State for new allergy input

  const nextOfKin = user?.nextOfKin || {};

  const [kinFirstName, setKinFirstName] = useState(nextOfKin?.firstName || '');
  const [kinLastName, setKinLastName] = useState(nextOfKin?.lastName || '');
  const [kinEmail, setKinEmail] = useState(nextOfKin?.email || '');
  const [kinPhoneNumber, setKinPhoneNumber] = useState(nextOfKin?.phoneNumber || '');
  const [kinCounty, setKinCounty] = useState(nextOfKin?.physicalAddress?.county || null);
  const [kinStreet, setKinStreet] = useState(nextOfKin?.physicalAddress?.street || '');
  const [kinRelationship, setKinRelationship] = useState(nextOfKin?.relationship || '');
  const [kinCurrentOccupation, setKinCurrentOccupation] = useState(nextOfKin?.occupation?.current || '');
  const [kinPastOccupation, setKinPastOccupation] = useState(nextOfKin?.occupation?.past || '');

  const addAllergy = () => {
    if (newAllergy && !allergies.includes(newAllergy)) {
      setAllergies([...allergies, newAllergy]);
      setNewAllergy(''); // Clear the input field after adding
    }
  };

  const removeAllergy = (allergyToRemove) => {
    setAllergies(allergies.filter((allergy) => allergy !== allergyToRemove));
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const genders = ['male', 'female'];

  const updateDetails = async () => {
    try {
      setError('');
      setSuccess('');
      if (!firstName || !lastName || !email || !phoneNumber) {
        setError('Missing required fields');
      } else {
        setLoading(true);

        const data = {
          firstName,
          lastName,
          email,
          phoneNumber,
          gender,
          dob,
          physicalAddress: {
            county,
            street,
          },
          nextOfKin: {
            firstName: kinFirstName,
            lastName: kinLastName,
            email: kinEmail,
            phoneNumber: kinPhoneNumber,
            relationship: kinRelationship,
            occupation: {
              current: kinCurrentOccupation,
              past: kinPastOccupation,
            },
            physicalAddress: {
              county: kinCounty,
              street: kinStreet,
            },
          },
          allergies, 
        };
        const result = await axios.patch(
          `${process.env.GATSBY_API_URL}/users/${user._id}`,
          {
            ...data,
          },
        );

        setUser(result.data);
        setSuccess('Success');
        setLoading(false);
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: EditProduct.js ~ line 54 ~ editProduct ~ error',
        err,
      );
      setLoading(false);
      const { message } = err.response.data;
      setError(message);
    }
  };

  const login = () => {
    navigate('/Login');
  };

  return (
    <Accordion defaultActiveKey="0" flush>
      <Accordion.Item>
        <SettingsBtn>Settings, fill in your personal information</SettingsBtn>
        <Accordion.Body>
          <UserContainer>
            <UserWrapper>
              <CardHeader>
                <Heading>My account</Heading>
              </CardHeader>
              <ProfileCard>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontFamily: 'Oswald', color: '#1d9682' }}
                >
                  Personal Information
                </Typography>
                <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
                <p style={{ color: 'green', textAlign: 'center' }}>{success}</p>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="FirstName"
                      name="FirstName"
                      label="First name"
                      fullWidth
                      autoComplete="fname"
                      style={{ fontFamily: 'Oswald' }}
                      autoFocus
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="lastName"
                      name="lastName"
                      label="Last name"
                      fullWidth
                      autoComplete="lname"
                      style={{ fontFamily: 'Oswald' }}
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="email"
                      name="email"
                      label="Email"
                      fullWidth
                      autoComplete="email"
                      style={{ fontFamily: 'Oswald' }}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      name="phone number"
                      label="Your Phone number"
                      fullWidth
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="date"
                      label="Date of Birth"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      style={{ fontFamily: 'Oswald' }}
                      value={dob}
                      onChange={(e) => {
                        setDob(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="gender"
                      name="gender"
                      label="Gender"
                      fullWidth
                      value={gender}
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                      select
                    >
                      {genders.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="county"
                      name="county"
                      label="County"
                      fullWidth
                      value={county}
                      onChange={(e) => {
                        setCounty(e.target.value);
                      }}
                      select
                    >
                      {kenyanCounties.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="street"
                      name="street"
                      label="Street"
                      fullWidth
                      value={street}
                      onChange={(e) => {
                        setStreet(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    fontFamily: 'Oswald',
                    color: '#1d9682',
                    margin: '2em 0',
                  }}
                >
                  Next of Kin Information
                </Typography>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="kinFirstName"
                      name="kinFirstName"
                      label="First Name"
                      fullWidth
                      value={kinFirstName}
                      onChange={(e) => {
                        setKinFirstName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="kinLastName"
                      name="kinLastName"
                      label="Last Name"
                      fullWidth
                      value={kinLastName}
                      onChange={(e) => {
                        setKinLastName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="kinEmail"
                      name="kinEmail"
                      label="Email"
                      fullWidth
                      value={kinEmail}
                      onChange={(e) => {
                        setKinEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="kinPhoneNumber"
                      name="kinPhoneNumber"
                      label="Phone Number"
                      fullWidth
                      value={kinPhoneNumber}
                      onChange={(e) => {
                        setKinPhoneNumber(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="kinCounty"
                      name="kinCounty"
                      label="County"
                      fullWidth
                      value={kinCounty}
                      onChange={(e) => {
                        setKinCounty(e.target.value);
                      }}
                      select
                    >
                      {kenyanCounties.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="kinStreet"
                      name="kinStreet"
                      label="Street"
                      fullWidth
                      value={kinStreet}
                      onChange={(e) => {
                        setKinStreet(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="kinRelationship"
                      name="kinRelationship"
                      label="Relationship"
                      fullWidth
                      value={kinRelationship}
                      onChange={(e) => {
                        setKinRelationship(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="kinCurrentOccupation"
                      name="kinCurrentOccupation"
                      label="Current Occupation"
                      fullWidth
                      value={kinCurrentOccupation}
                      onChange={(e) => {
                        setKinCurrentOccupation(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="kinPastOccupation"
                      name="kinPastOccupation"
                      label="Past Occupation"
                      fullWidth
                      value={kinPastOccupation}
                      onChange={(e) => {
                        setKinPastOccupation(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontFamily: 'Oswald', color: '#1d9682', marginTop: '2em' }}
                >
                  Allergies
                </Typography>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="allergies-input"
                      name="allergies"
                      label="Allergy"
                      fullWidth
                      value={newAllergy}
                      onChange={(e) => setNewAllergy(e.target.value)}
                      placeholder="Enter allergy"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button 
                      variant="contained" 
                      color="#1d9682" 
                      onClick={addAllergy}
                      style={{ fontSize: '15px' }}
                    >
                      Add Allergy
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <div>
                      <Typography
                        variant="h6" 
                        gutterBottom
                        style={{ fontFamily: 'Oswald', color: '#1d9682' }}
                      >
                        List of Allergies
                      </Typography>

                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {allergies.map((allergy, index) => (
                          <li
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '8px', 
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{ marginRight: '10px', flexGrow: 1 }}
                            >
                              {allergy}
                            </Typography>
                            <IconButton onClick={() => removeAllergy(allergy)}>
                              <DeleteIcon style={{ color: '#f44336' }} />
                            </IconButton>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Grid>
                </Grid>
                <TopButton
                  disableElevation
                  onClick={() => {
                    user ? updateDetails() : login();
                  }}
                  disabled={loading}
                >
                  Save
                  <SaveIcon />
                </TopButton>
              </ProfileCard>
            </UserWrapper>
          </UserContainer>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
